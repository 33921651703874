import { fetchSingletonAction } from 'actions/api'
import Heading from 'components/atoms/Heading/Heading'
import Loader from 'components/atoms/Loader/Loader'
import PlainText from 'components/atoms/PlainText/PlainText'
import Spacer from 'components/atoms/Spacer/Spacer'
import LinkIcon from 'components/molecules/LinkIcon/LinkIcon'
import ContactForm from 'components/organisms/ContactForm/ContactForm'
import PropTypes from 'prop-types'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import {
   Column,
   ColumnSeparator,
   Content,
   Wrapper,
} from 'templates/ColumnsTemplate/ColumnsTemplate'

class ContactView extends React.Component {
   componentDidMount() {
      const { fetchSingleton } = this.props
      fetchSingleton()
   }

   render() {
      const { contact } = this.props

      return (
         <>
            <Spacer large />
            <Heading>Kontakt</Heading>
            <Wrapper>
               <Content>
                  <Column>
                     {contact ? (
                        <PlainText align="right">
                           {ReactHtmlParser(contact.content)}
                           <Spacer small />
                           {contact.phone && <LinkIcon type="phone">{contact.phone}</LinkIcon>}
                           <Spacer small />
                           {contact.email && <LinkIcon type="mail">{contact.email}</LinkIcon>}
                        </PlainText>
                     ) : (
                        <Loader color="secondary" />
                     )}
                  </Column>
                  <ColumnSeparator />
                  <Column>
                     <ContactForm />
                  </Column>
               </Content>
            </Wrapper>
         </>
      )
   }
}

ContactView.propTypes = {
   contact: PropTypes.shape({
      content: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
   }),
   fetchSingleton: PropTypes.func.isRequired,
}

ContactView.defaultProps = {
   contact: null,
}

const mapStateToProps = ({ api }) => ({ contact: api.contact })

const mapDispatchToProps = (dispatch) => ({
   fetchSingleton: () => dispatch(fetchSingletonAction('contact')),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactView)
