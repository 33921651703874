export default {
   accent: '#3682fc',
   white: 'hsl(0, 100%, 100%)',
   black: 'hsl(0, 0%, 0%)',
   transparentWhite: 'hsla(0, 100%, 100%, 10%)',
   transparentWhiteHard: 'hsla(0, 100%, 100%, 30%)',
   transparentBlack: 'hsla(0, 0%, 0%, 10%)',
   transparentBlackHard: 'hsla(0, 0%, 0%, 30%)',
   placeholderColor: 'hsla(0, 0%, 0%, 50%)',
   dangerColor: 'hsl(0, 100%, 70%)',
   fontSize: {
      xs: '1rem',
      s: '1.4rem',
      m: '1.8rem',
      l: '2.4rem',
      xl: '4.8rem',
   },
   gradient: {
      vertical: 'linear-gradient(173.5deg, #0AADFC 14.55%, #8337FC 98.95%)',
      horizontal: 'linear-gradient(95.09deg, #0AADFC 2.95%, #8337FC 101.01%)',
      transparent:
         'linear-gradient(95.09deg, rgba(10, 173, 252, 0.5) 2.95%, rgba(131, 55, 252, 0.5) 101.01%)',
   },
   shadow: {
      standard: '0px 10px 20px -8px rgba(0, 0, 0, 0.25)',
      text: '0px 2px 2px rgba(0, 0, 0, 0.25)',
      nav: '0px 0px 20px rgba(0, 0, 0, 0.25)',
   },
   breakpoints: {
      max: {
         xs: '(max-width: 320px)',
         sm: '(max-width: 576px)',
         md: '(max-width: 768px)',
         lg: '(max-width: 992px)',
         xl: '(max-width: 1200px)',
         xxl: '(max-width: 1400px)',
      },
      min: {
         xs: '(min-width: 321px)',
         sm: '(min-width: 577px)',
         md: '(min-width: 769px)',
         lg: '(min-width: 993px)',
         xl: '(min-width: 1201px)',
         xxl: '(min-width: 1401px)',
      },
   },
}
