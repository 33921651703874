import styled, { css } from 'styled-components'

const Spacer = styled.div`
   ${({ small, large, theme }) => {
      if (small)
         return css`
            @media ${theme.breakpoints.min.md} {
               height: 35px;
               flex-basis: 35px;
            }

            @media ${theme.breakpoints.max.md} {
               height: 15px;
               flex-basis: 15px;
            }
         `
      if (large)
         return css`
            @media ${theme.breakpoints.min.md} {
               height: 100px;
               flex-basis: 100px;
            }

            @media ${theme.breakpoints.max.md} {
               height: 50px;
               flex-basis: 50px;
            }
         `
      return css`
         @media ${theme.breakpoints.min.md} {
            height: 75px;
            flex-basis: 75px;
         }

         @media ${theme.breakpoints.max.md} {
            height: 30px;
            flex-basis: 30px;
         }
      `
   }}
`

export default Spacer
