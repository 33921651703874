import { API_URL } from 'actions/api'
import { openLightboxAction } from 'actions/lightbox'
import fullScreenIcon from 'assets/icons/fullscreen.svg'
import linkIcon from 'assets/icons/link.svg'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

const StyledImage = styled.img`
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 16px;
`

const StyledInfoWrapper = styled.div`
   position: absolute;
   top: 4px;
   left: 4px;
   width: calc(100% - 8px);
   height: calc(100% - 8px);
   border-radius: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   background-color: ${({ theme }) => theme.transparentBlackHard};
   opacity: 0;
   transition: opacity 0.2s ease-out;
   pointer-events: none;

   ::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      background-color: ${({ color }) => color};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      opacity: 0.2;
   }
`

const StyledWrapper = styled.div`
   position: relative;
   background-color: ${({ color }) => color};
   width: 100%;
   height: 100%;
   border-radius: 20px;
   padding: 4px;

   :hover ${StyledInfoWrapper} {
      opacity: 1;
      backdrop-filter: blur(10px);
      pointer-events: all;
   }
`

const StyledTitle = styled.h1`
   font-size: ${({ theme }) => theme.fontSize.m};
   color: ${({ theme }) => theme.white};
   margin: 0;
   text-align: center;
`

const StyledInfoItems = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 0;
`

const StyledIcon = styled.div`
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-image: url(${({ icon }) => icon});
   background-color: ${({ theme }) => theme.transparentWhite};
   background-repeat: no-repeat;
   background-position: center;
   background-size: 24px;
   cursor: pointer;

   :hover {
      background-color: ${({ theme }) => theme.transparentWhiteHard};
   }
`

const StyledIconLabel = styled.span`
   position: absolute;
   font-size: ${({ theme }) => theme.fontSize.xs};
   color: ${({ theme }) => theme.white};
   left: 50%;
   will-change: transform;
   transition: opacity 0.2s ease-out, transform 0.2s ease-out;
   transform: translate(-50%, -80%);
   top: 55px;
   text-align: center;
   white-space: nowrap;
   opacity: 0;
   pointer-events: none;
`

const StyledLink = styled.a`
   display: block;
   margin: 25px 7.5px 0 7.5px;
   border-radius: 50%;
   position: relative;

   :hover ${StyledIconLabel} {
      opacity: 1;
      transform: translate(-50%, 0%);
   }
`

const StyledIconsWrapper = styled.div`
   display: flex;
`

const RealizationTile = ({ data, className, openLightbox }) => {
   return (
      <StyledWrapper className={className} color={data.color}>
         <StyledImage src={`${API_URL}/${data.thumbnail.path}`} alt={data.name} />
         <StyledInfoWrapper color={data.color}>
            <StyledInfoItems>
               <StyledTitle>{data.name}</StyledTitle>
               <StyledIconsWrapper>
                  {data.gallery && (
                     <StyledLink onClick={() => openLightbox(data.gallery, data.name)}>
                        <StyledIcon icon={fullScreenIcon} />
                        <StyledIconLabel>Galeria</StyledIconLabel>
                     </StyledLink>
                  )}
                  {data.link && (
                     <StyledLink href={data.link} target="_blank">
                        <StyledIcon icon={linkIcon} />
                        <StyledIconLabel>Przejdź do</StyledIconLabel>
                     </StyledLink>
                  )}
               </StyledIconsWrapper>
            </StyledInfoItems>
         </StyledInfoWrapper>
      </StyledWrapper>
   )
}

RealizationTile.propTypes = {
   className: PropTypes.string,
   data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
         path: PropTypes.string,
      }).isRequired,
      gallery: PropTypes.arrayOf(
         PropTypes.shape({
            meta: PropTypes.shape({
               title: PropTypes.string,
            }),
            path: PropTypes.string,
         }),
      ),
      link: PropTypes.string,
      color: PropTypes.string.isRequired,
   }).isRequired,
   openLightbox: PropTypes.func.isRequired,
}

RealizationTile.defaultProps = {
   className: null,
}

const mapDispatchToProps = (dispatch) => ({
   openLightbox: (images, title) => dispatch(openLightboxAction(images, title)),
})

export default connect(null, mapDispatchToProps)(RealizationTile)
