import ArrowIcon from 'assets/icons/arrow.svg'
import PropTypes from 'prop-types'
import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

const StyledArrowButton = styled.button`
   width: 50px;
   height: 50px;
   background-color: ${({ theme }) => theme.white};
   box-shadow: ${({ theme }) => theme.shadow.standard};
   border-radius: 50%;
   display: block;
   border: none;
   padding: 9px;
   margin: 0;
   cursor: pointer;
   position: relative;

   ::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.transparentBlack};
      top: 0;
      left: 0;
      opacity: 0;
      will-change: transform;
      transition: opacity 0.5s linear, transform 0.5s ease-out;
      transform: scale(1);
   }

   &:active::before {
      opacity: 1;
      transition: opacity 0s linear, transform 0s ease-out;
      transform: scale(0);
   }
`
const StyledIcon = styled(SVG)`
   width: 32px;
   height: 32px;

   transform: rotate(
      ${({ direction }) => {
         switch (direction) {
            case 'down':
               return '90deg'
            case 'left':
               return '180deg'
            case 'up':
               return '-90deg'
            default:
               return '0deg'
         }
      }}
   );

   * {
      fill: ${({ theme }) => theme.black};
   }
`

const ArrowButton = ({ direction, onClick, className }) => (
   <StyledArrowButton onClick={onClick} className={className}>
      <StyledIcon src={ArrowIcon} direction={direction} />
   </StyledArrowButton>
)

ArrowButton.propTypes = {
   direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
   onClick: PropTypes.func,
   className: PropTypes.string,
}

ArrowButton.defaultProps = {
   direction: null,
   onClick: null,
   className: null,
}

export default ArrowButton
