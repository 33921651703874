import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledError = styled.span`
   background-color: red;
   color: white;
   font-size: ${({ theme }) => theme.fontSize.xs};
   margin-bottom: 10px;
   padding: 5px 10px;
   border-radius: 20px;
   width: auto;
   display: inline-block;
`

const ErrorText = ({ children }) => (
   <div>
      <StyledError>{children}</StyledError>
   </div>
)

ErrorText.propTypes = {
   children: PropTypes.string,
}
ErrorText.defaultProps = {
   children: '',
}

export default ErrorText
