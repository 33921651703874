import ArrowButton from 'components/atoms/ArrowButton/ArrowButton'
import Heading from 'components/atoms/Heading/Heading'
import PlainText from 'components/atoms/PlainText/PlainText'
import PropTypes from 'prop-types'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import styled, { css, keyframes } from 'styled-components'
import {
   Column,
   ColumnSeparator,
   Content,
   Wrapper,
} from 'templates/ColumnsTemplate/ColumnsTemplate'

const Show = keyframes`
   from {
      opacity: 0;
      transform: translateY(20px);
   }
   to {
      opacity: 1;
      transform: translateY(0px);
   }
`

const ShowButton = keyframes`
   from {
      opacity: 0;
      transform: translateY(-50%) scale(0);
   }
   to {
      opacity: 1;
      transform: translateY(-50%) scale(1);
   }
`

const StyledHeading = styled(Heading)`
   animation: ${Show} 0.4s ease-out 0s;
`

const StyledPlainText = styled(PlainText)`
   animation: ${Show} 0.4s ease-out 0.2s backwards;
`

const StyledArrowButton = styled(ArrowButton)`
   z-index: 3;
   position: fixed !important;
   @media ${({ theme }) => theme.breakpoints.min.md} {
      top: 50vh;
   }
   @media ${({ theme }) => theme.breakpoints.max.md} {
      top: 45px;
   }

   transform: translateY(-50%);
   animation: ${ShowButton} 0.3s ease-out 0s;
   ${({ direction }) =>
      direction === 'left'
         ? css`
              @media ${({ theme }) => theme.breakpoints.min.md} {
                 left: 170px;
              }
              @media ${({ theme }) => theme.breakpoints.max.md} {
                 left: 20px;
              }
           `
         : css`
              right: 20px;
           `}
`

class OfferPages extends React.Component {
   state = {
      dataLeft: [],
      dataRight: [],
      leftColumnData: null,
      rightColumnData: null,
      page: 1,
   }

   componentDidMount() {
      const newDataLeft = []
      const newDataRight = []
      const { data } = this.props
      data.map((item, i) => {
         if (i % 2 === 0) {
            newDataLeft.push(item)
         } else {
            newDataRight.push(item)
         }
         return 0
      })
      this.setState({ dataLeft: newDataLeft, dataRight: newDataRight }, () => this.getColumnData())
   }

   getColumnData() {
      const { dataLeft, dataRight, page } = this.state
      this.setState({
         leftColumnData: dataLeft[page - 1] ? dataLeft[page - 1] : null,
         rightColumnData: dataRight[page - 1] ? dataRight[page - 1] : null,
      })
   }

   setPage(page) {
      this.setState(
         (prevState) => ({
            page: prevState.page + page,
            leftColumnData: null,
            rightColumnData: null,
         }),
         () => this.getColumnData(),
      )
   }

   render() {
      const { dataLeft, page, leftColumnData, rightColumnData } = this.state
      const isPrev = page > 1
      const isNext = page < dataLeft.length
      return (
         <Wrapper>
            {isPrev && <StyledArrowButton direction="left" onClick={() => this.setPage(-1)} />}
            <Content>
               <Column>
                  {leftColumnData && (
                     <>
                        <StyledHeading small align="right">
                           {leftColumnData.name}
                        </StyledHeading>
                        <StyledPlainText align="right">
                           {ReactHtmlParser(leftColumnData.content)}
                        </StyledPlainText>
                     </>
                  )}
               </Column>
               <ColumnSeparator />
               <Column>
                  {rightColumnData && (
                     <>
                        <StyledHeading small>{rightColumnData.name}</StyledHeading>
                        <StyledPlainText>
                           {ReactHtmlParser(rightColumnData.content)}
                        </StyledPlainText>
                     </>
                  )}
               </Column>
            </Content>
            {isNext && <StyledArrowButton direction="right" onClick={() => this.setPage(1)} />}
         </Wrapper>
      )
   }
}

OfferPages.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string.isRequired,
         content: PropTypes.string.isRequired,
      }),
   ),
}

OfferPages.defaultProps = {
   data: [],
}

export default OfferPages
