import styled from 'styled-components'

const Background = styled.div`
   position: fixed;
   top: 0;
   z-index: 0;
   background-size: cover;
   background-image: url(${({ src }) => src});
   background-position: center top;

   @media ${({ theme }) => theme.breakpoints.min.md} {
      left: 150px;
      width: calc(100% - 150px);
      height: 100vh;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      left: 0;
      width: 100%;
      height: calc(100vh - 70px);
   }
`

export default Background
