import checkIcon from 'assets/icons/check.svg'
import ErrorText from 'components/atoms/ErrorText/ErrorText'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

const StyledWrapper = styled.label`
   height: 30px;
   max-width: 100%;
   display: flex;
   user-select: none;
   cursor: pointer;
   margin: 10px 0;

   @media ${({ theme }) => theme.breakpoints.min.sm} {
      width: 300px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: calc(100%);
   }
`

const StyledLabel = styled.div`
   display: flex;
   height: 30px;
   padding-left: 15px;
   align-items: center;

   a {
      color: ${({ theme }) => theme.black};
   }
`

const StyledCheckbox = styled.div`
   display: block;
   width: 30px;
   height: 30px;
   border-radius: 10px;
   box-shadow: ${({ theme }) => theme.shadow.standard};
   position: relative;
   background-image: ${({ theme }) => theme.gradient.vertical};

   ::before {
      content: '';
      position: absolute;
      display: block;
      top: 2px;
      left: 2px;
      width: 26px;
      height: 26px;
      z-index: 2;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.white};
   }

   ::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.black};
      z-index: 1;
      opacity: 0;
      transition: opacity 0.1s ease-out;
   }
`

const StyledIcon = styled(SVG)`
   width: 24px;
   height: 24px;
   position: absolute;
   top: 3px;
   left: 3px;
   z-index: 3;
   opacity: 0;
   transform: scale(0);
   will-change: transform;
   transition: transform 0.2s ease-out, opacity 0.2s ease-out;
`

const StyledInput = styled.input`
   width: 0;
   height: 0;
   opacity: 0;
   pointer-events: none;
   margin: 0;

   :checked ~ ${StyledCheckbox} ${StyledIcon} {
      opacity: 1;
      transform: scale(1);
   }

   :not(:focus) ~ ${StyledCheckbox} {
      ${StyledIcon} * {
         fill: ${({ theme }) => theme.black};
      }
      ::after {
         opacity: 1;
      }
   }
`

const required = (value) => (value ? undefined : 'To pole jest wymagane')

const Checkbox = ({ className, children, name, onChange }) => (
   <Field name={name} type="checkbox" validate={required}>
      {({ input, meta }) => (
         <>
            <StyledWrapper className={className}>
               <StyledInput
                  type="checkbox"
                  name={name}
                  {...input}
                  onChange={(e) => {
                     onChange(e)
                     input.onChange(e)
                  }}
               />
               <StyledCheckbox>
                  <StyledIcon src={checkIcon} />
               </StyledCheckbox>
               <StyledLabel>{children}</StyledLabel>
            </StyledWrapper>
            {meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
         </>
      )}
   </Field>
)

Checkbox.propTypes = {
   name: PropTypes.string.isRequired,
   children: PropTypes.element.isRequired,
   className: PropTypes.string,
   onChange: PropTypes.func,
}

Checkbox.defaultProps = {
   className: null,
   onChange: () => {},
}

export default Checkbox
