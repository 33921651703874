import ArrowButton from 'components/atoms/ArrowButton/ArrowButton'
import Logo from 'components/atoms/Logo/Logo'
import ChatText from 'components/molecules/ChatText/ChatText'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 470px;
   margin-top: 100px;

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      padding-left: 60px;
   }
`

const StyledLogo = styled(Logo)`
   position: absolute;
   top: 0;
   left: 50%;
   transform: translate(-50%, 0);
   will-change: transform;
   transition: opacity 0.4s ease-out, transform 0.4s ease-out;
   margin: 0;

   &.hide {
      transform: translate(-50%, -90%) scale(0.7);
   }
`

const StyledAvater = styled.img`
   position: absolute;
   border-radius: 50%;

   @media ${({ theme }) => theme.breakpoints.min.lg} {
      width: 150px;
      height: 150px;
      left: -175px;
      bottom: 150px;
   }

   @media ${({ theme }) => `${theme.breakpoints.min.md} and ${theme.breakpoints.max.lg}`} {
      width: 100px;
      height: 100px;
      left: -115px;
      bottom: 150px;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      width: 50px;
      height: 50px;
      left: -50px;
      bottom: 200px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      left: 10px;
   }
`
const StyledScrollWrapper = styled.div`
   overflow: hidden;
   margin-bottom: 20px;

   @media ${({ theme }) => theme.breakpoints.min.xl} {
      width: 550px;
   }

   @media ${({ theme }) => `${theme.breakpoints.min.sm} and ${theme.breakpoints.max.xl}`} {
      width: 350px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: 100%;
   }
`

const StyledScrollContainer = styled.div`
   height: 400px;
   display: flex;
   flex-direction: column-reverse;
   overflow-y: auto;
   mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 1) 20px,
      rgba(0, 0, 0, 1) 380px,
      rgba(0, 0, 0, 0) 400px
   );

   @media ${({ theme }) => theme.breakpoints.min.xl} {
      width: 570px;
   }

   @media ${({ theme }) => `${theme.breakpoints.min.sm} and ${theme.breakpoints.max.xl}`} {
      width: 370px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: 100%;
   }
`

const StyledMessagesContainer = styled.div`
   padding-top: 20px;

   @media ${({ theme }) => theme.breakpoints.min.xl} {
      width: 550px;
   }

   @media ${({ theme }) => `${theme.breakpoints.min.sm} and ${theme.breakpoints.max.xl}`} {
      width: 350px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: 100%;
   }
`

class AboutWidget extends React.Component {
   state = {
      currentMessages: [],
      nextMessages: [],
   }

   componentDidMount() {
      const { messages } = this.props
      this.setState(
         {
            nextMessages: [...messages],
         },
         () => this.nextMessage(),
      )
   }

   nextMessage() {
      const { currentMessages, nextMessages } = this.state
      const nextMessage = nextMessages[0]
      nextMessages.shift()
      this.setState({
         currentMessages: [...currentMessages, nextMessage],
         nextMessages,
      })
   }

   render() {
      const { title, image } = this.props
      const { nextMessages, currentMessages } = this.state
      return (
         <StyledWrapper>
            <StyledLogo className={currentMessages.length > 1 ? 'hide' : null}>
               Inter-medial
            </StyledLogo>
            <StyledAvater src={image} />
            <StyledScrollWrapper>
               <StyledScrollContainer>
                  <StyledMessagesContainer>
                     {currentMessages &&
                        currentMessages.map(({ value }, i) => (
                           <ChatText key={`chat-text-${i}`} title={title}>
                              {value}
                           </ChatText>
                        ))}
                  </StyledMessagesContainer>
               </StyledScrollContainer>
            </StyledScrollWrapper>
            {nextMessages.length ? (
               <ArrowButton direction="down" onClick={() => this.nextMessage()} />
            ) : null}
         </StyledWrapper>
      )
   }
}

AboutWidget.propTypes = {
   messages: PropTypes.arrayOf(
      PropTypes.shape({
         value: PropTypes.string.isRequired,
      }),
   ).isRequired,
   title: PropTypes.string.isRequired,
   image: PropTypes.string.isRequired,
}

export default AboutWidget
