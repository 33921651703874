import mailIcon from 'assets/icons/contact.svg'
import phoneIcon from 'assets/icons/phone.svg'
import PropTypes from 'prop-types'
import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

const StyledWrapper = styled.div`
   display: flex;

   @media ${({ theme }) => theme.breakpoints.min.lg} {
      justify-content: flex-end;
   }

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      justify-content: center;
   }
`

const StyledLabel = styled.span`
   @media ${({ theme }) => theme.breakpoints.min.lg} {
      font-size: ${({ theme }) => theme.fontSize.l};
      padding-right: 15px;
   }

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      font-size: ${({ theme }) => theme.fontSize.m};
   }
`

const StyledIconCircle = styled.span`
   width: 48px;
   height: 48px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   background-image: ${({ theme }) => theme.gradient.vertical};
   position: relative;

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      margin-bottom: 20px;
   }

   ::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.white};
      z-index: 1;
      will-change: transform;
      transition: transform 0.2s ease-out;
   }
`

const StyledIcon = styled(SVG)`
   width: 24px;
   height: 24px;
   position: relative;
   z-index: 2;
`

const StyledLink = styled.a`
   display: flex;
   align-items: center;
   color: ${({ theme }) => theme.black};
   text-decoration: none;

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      flex-direction: column-reverse;
   }

   :hover {
      ${StyledIconCircle}::before {
         transform: scale(0);
      }
      ${StyledIconCircle} ${StyledIcon} * {
         fill: ${({ theme }) => theme.white};
      }
   }
`

const LinkIcon = ({ children, type }) => {
   const link = `${type === 'phone' ? 'tel:' : 'mailto:'}${children}`

   return (
      <StyledWrapper>
         <StyledLink href={link}>
            <StyledLabel>{children}</StyledLabel>
            <StyledIconCircle>
               <StyledIcon src={type === 'phone' ? phoneIcon : mailIcon} />
            </StyledIconCircle>
         </StyledLink>
      </StyledWrapper>
   )
}

LinkIcon.propTypes = {
   children: PropTypes.string.isRequired,
   type: PropTypes.oneOf(['phone', 'mail']).isRequired,
}

export default LinkIcon
