import { fetchCollectionAction } from 'actions/api'
import Loader from 'components/atoms/Loader/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PageTemplate from 'templates/PageTemplate/PageTemplate'
import Template404 from 'templates/Template404/Template404'

const PAGES = ['polityka-cookies', 'regulamin']

class PageView extends React.Component {
   state = {
      noPage: false,
   }

   componentDidMount() {
      const {
         location: { pathname },
      } = this.props

      if (PAGES.filter((page) => pathname === `/${page}`).length) {
         const { fetchCollection } = this.props
         fetchCollection()
      } else {
         this.setState({
            noPage: true,
         })
      }
   }

   getPage() {
      const {
         location: { pathname },
         pages,
      } = this.props

      return pages.find((x) => `/${x.name_slug}` === pathname)
   }

   render() {
      const data = this.getPage()
      const { noPage } = this.state

      return (
         <>
            {data ? (
               <PageTemplate title={data.name}>
                  <>{ReactHtmlParser(data.content)}</>
               </PageTemplate>
            ) : (
               <Loader />
            )}
            {noPage && <Template404 />}
         </>
      )
   }
}
const mapStateToProps = ({ api }) => ({ pages: api.pages })

const mapDispatchToProps = (dispatch) => ({
   fetchCollection: () => dispatch(fetchCollectionAction('pages')),
})

PageView.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string,
   }).isRequired,
   fetchCollection: PropTypes.func.isRequired,
   pages: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string,
         content: PropTypes.string,
         name_slug: PropTypes.string,
      }),
   ),
}

PageView.defaultProps = {
   pages: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageView))
