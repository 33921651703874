import PlainText from 'components/atoms/PlainText/PlainText'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Show = keyframes`
   from {
      opacity: 0;
      transform: translateY(20px);
   }
   to {
      opacity: 1;
      transform: translateY(0);
   }
`

const StyledTitle = styled.div`
   font-size: ${({ theme }) => theme.fontSize.m};
   color: ${({ theme }) => theme.white};
   margin-bottom: 10px;
   display: none;
`

const StyledWrapper = styled.div`
   padding: 0 10px 20px 10px;
   animation: ${Show} 0.4s ease-out;

   :last-child ${StyledTitle} {
      display: block;
   }
`

const StyledPlainText = styled(PlainText)`
   background-color: ${({ theme }) => theme.white};
   box-shadow: ${({ theme }) => theme.shadow.standard};
   padding: 25px;
   border-radius: 20px;
`

const ChatText = ({ title, children }) => (
   <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledPlainText big>{children}</StyledPlainText>
   </StyledWrapper>
)

ChatText.propTypes = {
   title: PropTypes.string.isRequired,
   children: PropTypes.string.isRequired,
}

export default ChatText
