import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import store from 'store'
import MainTemplate from 'templates/MainTemplate'
import ContactView from 'views/ContactView'
import HomeView from 'views/HomeView'
import OfferView from 'views/OfferView'
import PageView from 'views/PageView'
import RealizationsView from 'views/RealizationsView'

const Root = () => (
   <Provider store={store}>
      <BrowserRouter>
         <MainTemplate>
            <Switch>
               <Route path="/contact" component={ContactView} />
               <Route path="/realizations" component={RealizationsView} />
               <Route path="/offer" component={OfferView} />
               <Route path="/:page" component={PageView} />
               <Route path="/" component={HomeView} />
            </Switch>
         </MainTemplate>
      </BrowserRouter>
   </Provider>
)

export default Root
