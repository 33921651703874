import LogoIcon from 'assets/icons/logo.svg'
import PropTypes from 'prop-types'
import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

const Wrapper = styled.footer`
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   z-index: 1;
`

const Content = styled.div`
   color: ${({ white, theme }) => (white ? theme.white : theme.black)};
   font-size: ${({ theme }) => theme.fontSize.xs};
   line-height: 1.35em;
   position: relative;

   a {
      color: ${({ white, theme }) => (white ? theme.white : theme.black)};
   }

   @media ${({ theme }) => theme.breakpoints.min.md} {
      padding: 15px 15px 15px 165px;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      padding: 15px 15px 85px 15px;
   }
`

const LogoContainer = styled.div`
   width: 70px;
   height: 70px;
   padding: 10px;
   backdrop-filter: blur(10px);
`

const Logo = styled(SVG)`
   width: 50px;
   height: 50px;
   z-index: 1;

   path {
      fill: ${({ white, theme }) => (white ? theme.white : theme.black)};
   }
   circle {
      stroke: ${({ white, theme }) => (white ? theme.white : theme.black)};
   }
`

const Footer = ({ children, white }) => (
   <Wrapper>
      <Content white={white}>{children}</Content>
      <LogoContainer>
         <Logo white={white} src={LogoIcon} alt="Inter-medial logo" />
      </LogoContainer>
   </Wrapper>
)

Footer.propTypes = {
   children: PropTypes.element.isRequired,
   white: PropTypes.bool.isRequired,
}

export default Footer
