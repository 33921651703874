import Heading from 'components/atoms/Heading/Heading'
import PlainText from 'components/atoms/PlainText/PlainText'
import Separator from 'components/atoms/Separator/Separator'
import styled from 'styled-components'

export const Wrapper = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;

   @media ${({ theme }) => theme.breakpoints.min.lg} {
      padding: 0 100px;
   }
   @media ${({ theme }) => theme.breakpoints.max.lg} {
      padding: 0 20px;
   }
`

export const Content = styled.div`
   display: flex;

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      flex-direction: column;
      align-items: center;

      ${PlainText} {
         text-align: center !important;
      }
      ${Heading} {
         text-align: center !important;

         :before {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
         }
      }
   }
`

export const ColumnSeparator = styled(Separator)`
   position: relative;
   top: 10px;
`

export const Column = styled.div`
   @media ${({ theme }) => theme.breakpoints.min.lg} {
      padding: 0 35px;
   }

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      padding: 35px 0;
   }

   @media ${({ theme }) => theme.breakpoints.min.xl} {
      width: 450px;
   }

   @media ${({ theme }) => `${theme.breakpoints.min.sm} and ${theme.breakpoints.max.xl}`} {
      width: 400px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: 100%;
   }
`
