import contactBackground from 'assets/backgrounds/contact.webp'
import homeBackground from 'assets/backgrounds/home.webp'
import offerBackground from 'assets/backgrounds/offer.webp'
import realizationsBackground from 'assets/backgrounds/realizations.webp'
import Background from 'components/atoms/Background/Background'
import Footer from 'components/atoms/Footer/Footer'
import Navigation from 'components/molecules/Navigation/Navigation'
import Lightbox from 'components/organisms/Lightbox/Lightbox'
import PropTypes from 'prop-types'
import React from 'react'
import 'react-image-lightbox/style.css'
import { Link, withRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import GlobalStyle from 'theme/GlobalStyle'
import theme from 'theme/MainTheme'

const Content = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 1;

   @media ${theme.breakpoints.min.md} {
      padding-left: 150px;
      min-height: calc(100vh - 70px);
   }

   @media ${theme.breakpoints.max.md} {
      min-height: calc(100vh - 140px);
   }
`

const getBackground = (pathname) => {
   switch (pathname) {
      case '/offer':
         return offerBackground
      case '/realizations':
         return realizationsBackground
      case '/contact':
         return contactBackground
      default:
         return homeBackground
   }
}
const getFooterColor = (pathname) => {
   switch (pathname) {
      case '/offer':
         return false
      case '/realizations':
         return true
      case '/contact':
         return false
      default:
         return true
   }
}

const MainTemplate = ({ children, location }) => {
   const { pathname } = location

   const background = getBackground(pathname)
   const footerColor = getFooterColor(pathname)

   return (
      <>
         <GlobalStyle />
         <ThemeProvider theme={theme}>
            <Navigation />
            <Background src={background} />
            <Content>{children}</Content>
            <Footer white={footerColor}>
               NIP: 537-110-55-17
               <br />
               TELEFON: 791-950-900
               <br />
               <Link to="/polityka-cookies">Polityka cookies</Link>
            </Footer>
            <Lightbox />
         </ThemeProvider>
      </>
   )
}

MainTemplate.propTypes = {
   children: PropTypes.element.isRequired,
   location: PropTypes.shape({
      pathname: PropTypes.string,
   }).isRequired,
}

export default withRouter(MainTemplate)
