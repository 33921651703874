import Heading from 'components/atoms/Heading/Heading'
import Spacer from 'components/atoms/Spacer/Spacer'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
   max-width: 100%;
   width: 900px;
   padding: 0 20px;
`

const StyledContainer = styled.div`
   width: 100%;
   background-color: ${({ theme }) => theme.white};
   border-radius: 15px;
   padding: 30px;
   box-shadow: ${({ theme }) => theme.shadow.standard};
   min-height: 500px;
`

const PageTemplate = ({ title, children }) => (
   <StyledWrapper>
      <Spacer large />
      <Heading white align="center">
         {title}
      </Heading>
      <StyledContainer>{children}</StyledContainer>
   </StyledWrapper>
)

PageTemplate.propTypes = {
   title: PropTypes.string.isRequired,
   children: PropTypes.element.isRequired,
}

export default PageTemplate
