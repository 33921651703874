import PropTypes from 'prop-types'
import React from 'react'
import SVG from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledIcon = styled(SVG)`
   position: absolute;
   z-index: 1;

   @media ${({ theme }) => theme.breakpoints.min.md} {
      width: 50px;
      height: 50px;
      top: 15px;
      left: 15px;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      width: 40px;
      height: 40px;
      top: 10px;
      left: 10px;
      transform: translateY(0);
      transition: transform 0.2s linear;
   }
`

const StyledNavLink = styled(NavLink)`
   display: block;
   position: relative;

   ::after,
   ::before {
      content: '';
      position: absolute;
      width: 80px;
      height: 80px;
      top: 0;
      left: 0;
      z-index: 0;
   }

   ::after {
      content: '';
      background-color: ${({ theme }) => theme.white};
      opacity: 0;
      transition: opacity 0.3s linear;
      border-radius: 20px;
   }

   &.active {
      ::before {
         transform: scale(1.3);
         border-radius: 24px;
         border-width: 3px;
      }

      ::after {
         opacity: 1;
      }

      span {
         opacity: 0;
      }
   }

   :not(.active) {
      svg * {
         fill: white;
      }

      @media ${({ theme }) => theme.breakpoints.max.md} {
         svg {
            transform: translateY(-3px);
         }
      }
   }

   @media ${({ theme }) => theme.breakpoints.min.md} {
      width: 80px;
      height: 94px;
      margin: 10px 0;

      ::before {
         background: transparent;
         border: 4px solid ${({ theme }) => theme.white};
         transition: transform 0.3s ease-out;
         border-radius: 20px;
      }
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      width: 60px;
      height: 60px;
      margin: 0 10px;

      ::after {
         width: 60px;
         height: 60px;
      }

      ::before {
         display: none;
      }
   }
`

const StyledLabel = styled.span`
   width: 100%;
   position: absolute;
   bottom: 0;
   font-size: ${({ theme }) => theme.fontSize.xs};
   color: ${({ theme }) => theme.white};
   text-align: center;
   display: block;
   height: 10px;
   transition: opacity 0.2s linear;
   z-index: 1;
`

const NavButton = ({ to, icon, children, exact }) => (
   <StyledNavLink exact={exact} to={to}>
      <StyledIcon src={icon} />
      <StyledLabel>{children}</StyledLabel>
   </StyledNavLink>
)

NavButton.propTypes = {
   icon: PropTypes.string.isRequired,
   children: PropTypes.string.isRequired,
   to: PropTypes.string.isRequired,
   exact: PropTypes.bool,
}

NavButton.defaultProps = {
   exact: false,
}

export default NavButton
