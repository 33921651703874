import PropTypes from 'prop-types'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Show = keyframes`
   0% {
      width: 0;
      opacity: 0;
   }
   1% {
      width: auto;
   }
   100% {
      opacity: 1;
   }
`

const StyledLogo = styled.h1`
   font-family: 'Roboto', sans-serif;
   color: white;
   text-transform: uppercase;
   display: flex;
   @media ${({ theme }) => theme.breakpoints.min.md} {
      font-size: 86px;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      font-size: 12vw;
   }
`

const StyledLetter = styled.span`
   display: block;
   animation: ${Show} 0.1s ${({ index }) => 0.1 * index}s ease-out backwards;
`

class Logo extends React.Component {
   state = {
      textArray: [],
   }

   componentDidMount() {
      const { children } = this.props
      const textArray = children.split('')
      this.setState({ textArray })
   }

   render() {
      const { className } = this.props
      const { textArray } = this.state
      return (
         <StyledLogo className={className}>
            {textArray.length &&
               textArray.map((letter, i) => (
                  <StyledLetter index={i} key={`letter-${i}`}>
                     {letter}
                  </StyledLetter>
               ))}
         </StyledLogo>
      )
   }
}

Logo.propTypes = {
   children: PropTypes.string.isRequired,
   className: PropTypes.string,
}

Logo.defaultProps = {
   className: null,
}

export default Logo
