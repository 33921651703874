/* eslint-disable no-underscore-dangle */
import { fetchCollectionAction } from 'actions/api'
import Heading from 'components/atoms/Heading/Heading'
import Loader from 'components/atoms/Loader/Loader'
import Spacer from 'components/atoms/Spacer/Spacer'
import CarouselButton from 'components/organisms/CarouselButton/CarouselButton'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import OfferPagesTempolate from 'templates/OfferPagesTemplate/OfferPagesTemplate'

const animationspeed = 60

const getImages = (realizations) => {
   const filterRealizations = realizations.sort((a, b) => {
      if (a._created > b._created) return -1
      if (a._created < b._created) return 1
      return 0
   })

   if (filterRealizations.length) {
      const images = []
      for (let i = 0; i < 5; i++) {
         if (filterRealizations[i]) {
            images.push(filterRealizations[i].thumbnail.path)
         } else {
            images.push(filterRealizations[filterRealizations.length - 1].thumbnail.path)
         }
      }
      return images
   }
   return null
}

class OfferView extends React.Component {
   componentDidMount() {
      const { fetchOffer, fetchRealizations } = this.props
      fetchOffer()
      fetchRealizations()
   }

   render() {
      const { offer, realizations } = this.props
      const images = getImages(realizations)

      return (
         <>
            <Spacer large />
            <Heading>Oferta</Heading>
            {offer.length ? <OfferPagesTempolate data={offer} /> : <Loader color="secondary" />}
            <Spacer large />
            {images ? (
               <CarouselButton images={images} animationspeed={animationspeed} to="/realizations">
                  Zobacz nasze realizacje
               </CarouselButton>
            ) : (
               <Loader color="secondary" />
            )}
         </>
      )
   }
}

OfferView.propTypes = {
   offer: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string,
         content: PropTypes.string,
      }),
   ),
   realizations: PropTypes.arrayOf(
      PropTypes.shape({
         _id: PropTypes.string,
         thumbnail: PropTypes.shape({
            path: PropTypes.string,
         }),
      }),
   ),
   fetchOffer: PropTypes.func.isRequired,
   fetchRealizations: PropTypes.func.isRequired,
}

OfferView.defaultProps = {
   offer: [],
   realizations: [],
}

const mapStateToProps = ({ api }) => ({ offer: api.offer, realizations: api.realizations })

const mapDispatchToProps = (dispatch) => ({
   fetchOffer: () => dispatch(fetchCollectionAction('offer')),
   fetchRealizations: () => dispatch(fetchCollectionAction('realizations')),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfferView)
