import { API_URL, fetchSingletonAction } from 'actions/api'
import arrowIcon from 'assets/icons/arrow.svg'
import Button from 'components/atoms/Button/Button'
import Loader from 'components/atoms/Loader/Loader'
import Spacer from 'components/atoms/Spacer/Spacer'
import AboutWidget from 'components/organisms/AboutWidget/AboutWidget'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

class HomeView extends React.Component {
   componentDidMount() {
      const { fetchSingleton } = this.props
      fetchSingleton()
   }

   render() {
      const { about } = this.props
      return (
         <>
            <Spacer small />
            {about ? (
               <AboutWidget
                  messages={about.chat}
                  title={about.name}
                  image={`${API_URL}/${about.avatar.path}`}
               />
            ) : (
               <Loader />
            )}
            <Spacer large />
            <Button icon={arrowIcon} big to="/offer">
               Sprawdź naszą ofertę
            </Button>
         </>
      )
   }
}

HomeView.propTypes = {
   about: PropTypes.shape({
      avatar: PropTypes.shape({ path: PropTypes.string }),
      name: PropTypes.string,
      chat: PropTypes.arrayOf(
         PropTypes.shape({
            value: PropTypes.string,
         }),
      ),
   }),
   fetchSingleton: PropTypes.func.isRequired,
}

HomeView.defaultProps = {
   about: null,
}

const mapStateToProps = ({ api }) => ({ about: api.about })

const mapDispatchToProps = (dispatch) => ({
   fetchSingleton: () => dispatch(fetchSingletonAction('about')),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
