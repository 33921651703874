import ErrorText from 'components/atoms/ErrorText/ErrorText'
import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import { Field } from 'react-final-form'
import styled, { css } from 'styled-components'

const StyledGradient = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: ${({ theme }) => theme.gradient.horizontal};
   z-index: 1;
   border-radius: 20px;
   opacity: 0;
   transition: opacity 0.1s ease-out;
`

const StyledLabel = styled.label`
   color: ${({ theme }) => theme.black};
   font-size: ${({ theme }) => theme.fontSize.s};
   position: absolute;
   top: 13px;
   left: 18px;
   z-index: 3;
   opacity: 0.5;
   will-change: transform;
   transition: transform 0.2s ease-out, opacity 0.2s ease-out;
   transform-origin: left;
   pointer-events: none;
`

const StyledInput = styled.input`
   width: 100%;
   height: 36px;
   padding: 10px 16px;
   border-radius: 18px;
   border: none;
   background-color: ${({ theme }) => theme.white};
   outline: none;
   position: relative;
   z-index: 2;
   font-size: ${({ theme }) => theme.fontSize.s};

   &:focus ~ ${StyledGradient} {
      opacity: 1;
   }

   &:focus ~ ${StyledLabel} {
      opacity: 1;
      transform: scale(0.8) translateY(-35px);
   }
`

const StyledWrapper = styled.div`
   margin: 30px 0 10px 0;
   max-width: 100%;
   padding: 2px;
   box-shadow: ${({ theme }) => theme.shadow.standard};
   border-radius: 20px;
   background-color: ${({ theme }) => theme.white};
   position: relative;

   @media ${({ theme }) => theme.breakpoints.min.sm} {
      width: 300px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: calc(100%);
   }

   ::before {
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 2px solid ${({ theme }) => theme.dangerColor};
      position: absolute;
      z-index: 0;
      border-radius: 20px;
      transition: opacity 0.1s ease-out;
      opacity: ${({ error }) => (error ? 1 : 0)};
   }

   ${({ active }) =>
      active &&
      css`
         ${StyledLabel} {
            opacity: 1;
            transform: scale(0.8) translateY(-35px);
         }
      `}
`

const StyledTextarea = styled.textarea`
   height: 150px;
   margin-bottom: -3px;
   resize: none;
   line-height: 1.5em;
`
const required = (value) => (value ? undefined : 'To pole jest wymagane')

class Input extends React.Component {
   constructor(props) {
      super(props)
      this.input = createRef()
   }

   state = {
      value: '',
   }

   componentDidUpdate() {
      const { value } = this.input.current
      const { value: stateValue } = this.state
      if (stateValue !== value) {
         this.setValue(value)
      }
   }

   setValue(value) {
      this.setState({ value })
   }

   render() {
      const { className, id, name, placeholder, textarea, onChange } = this.props
      const { value } = this.state
      return (
         <Field name={name} validate={required}>
            {({ input, meta }) => (
               <>
                  <StyledWrapper
                     className={className}
                     error={meta.error && meta.touched}
                     active={value !== ''}
                  >
                     <StyledInput
                        id={id}
                        name={name}
                        as={textarea ? StyledTextarea : null}
                        ref={this.input}
                        {...input}
                        onChange={(e) => {
                           onChange(e)
                           input.onChange(e)
                        }}
                     />
                     <StyledGradient />
                     <StyledLabel htmlFor={id}>{placeholder}</StyledLabel>
                  </StyledWrapper>
                  {meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
               </>
            )}
         </Field>
      )
   }
}

Input.propTypes = {
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   placeholder: PropTypes.string,
   textarea: PropTypes.bool,
   className: PropTypes.string,
   onChange: PropTypes.func,
}

Input.defaultProps = {
   placeholder: '',
   textarea: false,
   className: null,
   onChange: () => {},
}

export default Input
