import styled, { css } from 'styled-components'

const Heading = styled.h1`
   position: relative;
   margin: 20px 0;
   font-size: ${({ theme, small }) => (small ? theme.fontSize.l : theme.fontSize.xl)};
   color: ${({ theme, white }) => (white ? theme.white : theme.black)};
   ${({ theme, white }) =>
      white &&
      css`
         text-shadow: ${theme.shadow.text};
      `}
   ${({ theme, small, align, white }) =>
      small &&
      css`
         padding-bottom: 10px;
         ::before {
            content: '';
            position: absolute;
            width: 100px;
            border-bottom: 2px dotted ${white ? theme.white : theme.black};
            bottom: 0;
            ${() => {
               switch (align) {
                  case 'center':
                     return css`
                        left: 50%;
                        transform: translateX(-50%);
                     `
                  case 'right':
                     return css`
                        right: 0;
                     `
                  default:
                     return css`
                        left: 0;
                     `
               }
            }}
         }
      `}
   text-align: ${({ align }) => {
      switch (align) {
         case 'center':
            return 'center'
         case 'right':
            return 'right'
         default:
            return 'left'
      }
   }};
`

export default Heading
