import styled from 'styled-components'

const Separator = styled.div`
   position: relative;

   ::after,
   ::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.black};
      position: absolute;
   }

   @media ${({ theme }) => theme.breakpoints.min.lg} {
      width: 2px;
      height: auto;
      margin: 0 10px;
      min-height: 200px;
      border-left: 2px dotted ${({ theme }) => theme.black};
      ::after {
         top: 0;
         left: -5px;
      }

      ::before {
         bottom: 0;
         left: -5px;
      }
   }

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      width: 50%;
      height: 2px;
      margin: 10px 0;
      border-top: 2px dotted ${({ theme }) => theme.black};
      ::after {
         left: 0;
         top: -5px;
      }

      ::before {
         right: 0;
         top: -5px;
      }
   }
`

export default Separator
