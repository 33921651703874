import Button from 'components/atoms/Button/Button'
import Spacer from 'components/atoms/Spacer/Spacer'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
   max-width: 100%;
   padding: 20px;
   width: 900px;
   color: ${({ theme }) => theme.white};
   display: flex;
   flex-direction: column;
   align-items: center;
`

const StyledHeader = styled.h1`
   font-family: 'Roboto', sans-serif;
   text-align: center;
   margin: 0;
   color: ${({ theme }) => theme.transparentWhiteHard};

   @media ${({ theme }) => theme.breakpoints.min.sm} {
      font-size: 200px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      font-size: 50vw;
   }
`

const StyledText = styled.div`
   font-size: ${({ theme }) => theme.fontSize.m};
   text-align: center;
`

const Template404 = () => (
   <StyledWrapper>
      <Spacer large />
      <StyledHeader>404</StyledHeader>
      <Spacer small />
      <StyledText>Ups! Niestety nie ma takiej strony lub została ona usunięta.</StyledText>
      <Spacer small />
      <Button background="transparent" big to="/">
         Wróć do strony głównej
      </Button>
   </StyledWrapper>
)

export default Template404
