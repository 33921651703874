import { API_URL } from 'actions/api'
import Button from 'components/atoms/Button/Button'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const imageSlide = (slide) => {
   if (slide === 0) {
      return keyframes`
         0% {
            transform: translateX(900px);
         }
         100% {
            transform: translateX(-225px);
         }
      `
   }

   return keyframes`
      0% {
         transform: translateX(${900 - slide * 225}px);
      }
      ${100 - slide * 20}% {
         transform: translateX(-225px);
      }
      ${100 - slide * 20 + 0.0001}% {
         transform: translateX(900px);
      }
      100% {
         transform: translateX(${900 - slide * 225}px);
      }
   `
}

const StyledWrapper = styled.div`
   height: 150px;
   width: 100%;
   max-width: 900px;
   position: relative;
   border-radius: 75px;
   border: 4px solid ${({ theme }) => theme.white};
   background-color: ${({ theme }) => theme.white};
   overflow: hidden;
`

const StyledButtonWrapper = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: ${({ theme }) => theme.gradient.transparent};
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 40px;
   z-index: 1;
`

const StyledSliderImage = styled.img`
   position: absolute;
   width: 225px;
   height: 150px;
   object-fit: cover;
   top: 0;
   will-change: transform;
   ${({ count, speed }) => css`
      animation: ${imageSlide(count)} ${speed}s linear infinite;
   `}
`

const StyledSliderWrapper = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
`

const CarouselButton = ({ children, images, to, animationspeed }) => (
   <StyledWrapper>
      <StyledSliderWrapper imagesCount={images.length}>
         {images.slice(0, 5).map((img, i) => (
            <StyledSliderImage
               key={`slider-image-${i}`}
               count={i}
               speed={animationspeed}
               src={`${API_URL}/${img}`}
            />
         ))}
      </StyledSliderWrapper>
      <StyledButtonWrapper>
         <Button to={to} background="white">
            {children}
         </Button>
      </StyledButtonWrapper>
   </StyledWrapper>
)

CarouselButton.propTypes = {
   images: PropTypes.arrayOf(PropTypes.string).isRequired,
   to: PropTypes.string,
   animationspeed: PropTypes.number,
   children: PropTypes.string.isRequired,
}

CarouselButton.defaultProps = {
   to: null,
   animationspeed: 60,
}

export default CarouselButton
