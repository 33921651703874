import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
   padding: 30px;
   display: flex;
   justify-content: center;
`

const Loader = ({ color }) => (
   <StyledWrapper>
      <CircularProgress color={color} />
   </StyledWrapper>
)

Loader.propTypes = {
   color: PropTypes.oneOf('primary', 'secondary'),
}

Loader.defaultProps = {
   color: 'primary',
}

export default Loader
