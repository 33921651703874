/* eslint-disable no-underscore-dangle */
import { fetchCollectionAction } from 'actions/api'
import sendMailIcon from 'assets/icons/sendmail.svg'
import Button from 'components/atoms/Button/Button'
import Heading from 'components/atoms/Heading/Heading'
import Loader from 'components/atoms/Loader/Loader'
import Spacer from 'components/atoms/Spacer/Spacer'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import RealizationsGridTemplate from 'templates/RealizationsGridTemplate/RealizationsGridTemplate'

const StyledButton = styled(Button)`
   @media ${({ theme }) => theme.breakpoints.max.md} {
      position: fixed;
      bottom: 130px;
      left: 50%;
      transform: translateX(-50%);
   }
`

class RealizationsView extends React.Component {
   componentDidMount() {
      const { fetchCollection } = this.props
      fetchCollection()
   }

   render() {
      const { realizations } = this.props
      const filterRealizations = [...realizations]
      filterRealizations.sort((a, b) => {
         if (a._created > b._created) return -1
         if (a._created < b._created) return 1
         return 0
      })
      filterRealizations.sort((a, b) => {
         if (!a.date && !b.date) return 0
         if (!a.date) return 1
         if (!b.date) return -1
         return b.date.localeCompare(a.date)
      })

      return (
         <>
            <Spacer large />
            <Heading white>Realizacje</Heading>
            {realizations.length ? (
               <RealizationsGridTemplate data={filterRealizations} />
            ) : (
               <Loader />
            )}
            <Spacer small />
            <StyledButton background="gradient" icon={sendMailIcon} big to="/contact">
               Złóż zamówienie
            </StyledButton>
         </>
      )
   }
}

RealizationsView.propTypes = {
   realizations: PropTypes.arrayOf(
      PropTypes.shape({
         _id: PropTypes.string,
         name: PropTypes.string,
         thumbnail: PropTypes.shape({
            path: PropTypes.string,
         }),
         link: PropTypes.string,
         gallery: PropTypes.arrayOf(
            PropTypes.shape({
               meta: PropTypes.shape({
                  title: PropTypes.string,
               }),
               path: PropTypes.string,
            }),
         ),
         color: PropTypes.string,
         highlight: PropTypes.bool,
         date: PropTypes.string.isRequired,
      }),
   ),
   fetchCollection: PropTypes.func.isRequired,
}

RealizationsView.defaultProps = {
   realizations: [],
}

const mapStateToProps = ({ api }) => ({ realizations: api.realizations })

const mapDispatchToProps = (dispatch) => ({
   fetchCollection: () => dispatch(fetchCollectionAction('realizations')),
})

export default connect(mapStateToProps, mapDispatchToProps)(RealizationsView)
