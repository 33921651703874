import { API_TOKEN, API_URL } from 'actions/api'
import axios from 'axios'
import Button from 'components/atoms/Button/Button'
import Checkbox from 'components/molecules/Checkbox/Checkbox'
import Input from 'components/molecules/Input/Input'
import React from 'react'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledWrapper = styled.form`
   max-width: 100%;
   display: flex;
   flex-direction: column;

   @media ${({ theme }) => theme.breakpoints.max.lg} {
      margin: 0 auto;
   }

   @media ${({ theme }) => theme.breakpoints.min.sm} {
      width: 300px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: calc(100%);
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      align-items: center;
   }
`

const StyledButton = styled(Button)`
   margin-top: 10px;
`

const StyledInfoText = styled.div`
   text-align: center;
   font-size: ${({ theme }) => theme.fontSize.s};
   padding: 10px;
   border-radius: 20px;
   background-color: ${({ type }) => (type === 'error' ? 'red' : '#1ff031')};
   color: ${({ type }) => (type === 'error' ? 'white' : 'black')};
   margin-top: 30px;
`

class ContactForm extends React.Component {
   state = {
      isError: false,
      isSuccess: false,
   }

   async onSubmit(values, form) {
      this.resetState()

      await axios
         .post(`${API_URL}/api/forms/submit/contactform?token=${API_TOKEN}`, {
            form: { ...values },
         })
         .then(() => {
            setTimeout(form.restart)
            this.setState({ isSuccess: true })
         })
         .catch(() => {
            this.setState({ isError: true })
         })
   }

   resetState() {
      const { isError, isSuccess } = this.state
      if (isError || isSuccess) {
         this.setState({
            isError: false,
            isSuccess: false,
         })
      }
   }

   render() {
      const { isError, isSuccess } = this.state
      return (
         <Form
            onSubmit={(values, form) => {
               this.onSubmit.call(this, values, form)
            }}
            render={({ handleSubmit, submitting }) => (
               <StyledWrapper onSubmit={handleSubmit}>
                  <Input
                     id="form-name"
                     name="name"
                     placeholder="Imię i nazwisko"
                     onChange={() => this.resetState()}
                  />
                  <Input
                     id="form-contact"
                     name="contact"
                     placeholder="Telefon lub email"
                     onChange={() => this.resetState()}
                  />
                  <Input
                     id="message"
                     name="message"
                     placeholder="Wiadomość"
                     textarea
                     onChange={() => this.resetState()}
                  />
                  <Checkbox name="accept-regulamin" onChange={() => this.resetState()}>
                     <>
                        Akceptuje&nbsp;<Link to="/regulamin">regulamin</Link>
                     </>
                  </Checkbox>
                  <StyledButton type="submit" background="gradient" disabled={submitting}>
                     Wyślij
                  </StyledButton>
                  {isError && (
                     <StyledInfoText type="error">Nie udało się wysłać widomości</StyledInfoText>
                  )}
                  {isSuccess && <StyledInfoText type="success">Wiadomość wysłana</StyledInfoText>}
               </StyledWrapper>
            )}
         />
      )
   }
}

export default ContactForm
