import styled from 'styled-components'

const PlainText = styled.div`
   color: ${({ theme }) => theme.black};
   font-size: ${({ theme, big }) => (big ? theme.fontSize.m : theme.fontSize.s)};
   line-height: 1.5em;
   text-align: ${({ align }) => {
      switch (align) {
         case 'center':
            return 'center'
         case 'right':
            return 'right'
         default:
            return 'left'
      }
   }};
`

export default PlainText
