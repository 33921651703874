import { API_URL } from 'actions/api'
import {
   closeLightboxAction,
   lightboxNextImageAction,
   lightboxPrevImageAction,
} from 'actions/lightbox'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ImageLightbox from 'react-image-lightbox'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const Lightbox = ({
   lightboxImages,
   isLightboxOpen,
   lightboxImageIndex,
   closeLightbox,
   lightboxNextImage,
   lightboxPrevImage,
   lightboxTitle,
   location,
}) => {
   const { pathname } = location
   useEffect(() => {
      if (pathname !== '/realizations' && isLightboxOpen) {
         closeLightbox()
      }
   })

   return (
      <>
         {isLightboxOpen && lightboxImages.length && (
            <ImageLightbox
               mainSrc={`${API_URL}/${lightboxImages[lightboxImageIndex].path}`}
               nextSrc={`${API_URL}/${
                  lightboxImages[(lightboxImageIndex + 1) % lightboxImages.length].path
               }`}
               prevSrc={`${API_URL}/${
                  lightboxImages[
                     (lightboxImageIndex + lightboxImages.length - 1) % lightboxImages.length
                  ].path
               }`}
               imageTitle={lightboxTitle}
               imageCaption={
                  lightboxImages[lightboxImageIndex].meta.title !== ''
                     ? lightboxImages[lightboxImageIndex].meta.title
                     : null
               }
               onCloseRequest={() => closeLightbox()}
               onMoveNextRequest={() => lightboxNextImage()}
               onMovePrevRequest={() => lightboxPrevImage()}
               imagePadding={50}
            />
         )}
      </>
   )
}

Lightbox.propTypes = {
   lightboxImages: PropTypes.arrayOf(
      PropTypes.shape({
         meta: PropTypes.shape({
            title: PropTypes.string,
         }),
         path: PropTypes.string,
      }),
   ).isRequired,
   isLightboxOpen: PropTypes.bool.isRequired,
   lightboxImageIndex: PropTypes.number.isRequired,
   lightboxTitle: PropTypes.string.isRequired,
   closeLightbox: PropTypes.func.isRequired,
   lightboxNextImage: PropTypes.func.isRequired,
   lightboxPrevImage: PropTypes.func.isRequired,
   location: PropTypes.shape({
      pathname: PropTypes.string,
   }).isRequired,
}

const mapStateToProps = ({ lightbox }) => {
   const { lightboxImages, isLightboxOpen, lightboxImageIndex, lightboxTitle } = lightbox
   return {
      lightboxImages,
      isLightboxOpen,
      lightboxImageIndex,
      lightboxTitle,
   }
}

const mapDispatchToProps = (dispatch) => ({
   closeLightbox: () => dispatch(closeLightboxAction()),
   lightboxNextImage: () => dispatch(lightboxNextImageAction()),
   lightboxPrevImage: () => dispatch(lightboxPrevImageAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lightbox))
