import contactIcon from 'assets/icons/contact.svg'
import homeIcon from 'assets/icons/home.svg'
import LogoIcon from 'assets/icons/logo.svg'
import offerIcon from 'assets/icons/offer.svg'
import realizationsIcon from 'assets/icons/realizations.svg'
import NavButton from 'components/atoms/NavButton/NavButton'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.nav`
   width: 150px;
   height: 100vh;
   display: flex;
   align-items: center;
   position: fixed;
   left: 0;
   z-index: 2;
   box-shadow: ${({ theme }) => theme.shadow.nav};

   @media ${({ theme }) => theme.breakpoints.min.md} {
      background-image: ${({ theme }) => theme.gradient.vertical};
      flex-direction: column;
      top: 0;
      justify-content: center;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      height: 70px;
      width: 100%;
      bottom: 0;
      background-image: ${({ theme }) => theme.gradient.horizontal};
      justify-content: space-around;
   }
`

const Logo = styled.img`
   width: 100px;
   height: 100px;
   position: absolute;
   top: 25px;
   left: 25px;

   @media ${({ theme }) => theme.breakpoints.max.md} {
      display: none;
   }
`

const Navigation = () => (
   <StyledWrapper>
      <Logo src={LogoIcon} alt="INTER-MEDIAL LOGO" />
      <NavButton icon={homeIcon} exact to="/">
         Start
      </NavButton>
      <NavButton icon={offerIcon} to="/offer">
         Oferta
      </NavButton>
      <NavButton icon={realizationsIcon} to="/realizations">
         Realizacje
      </NavButton>
      <NavButton icon={contactIcon} to="/contact">
         Kontakt
      </NavButton>
   </StyledWrapper>
)

export default Navigation
