import scrollbarArrowIcon from 'assets/icons/scrollbararrow.svg'
import RealizationTile from 'components/molecules/RealizationTile/RealizationTile'
import PropTypes from 'prop-types'
import React from 'react'
import SVG from 'react-inlinesvg'
import styled, { css, keyframes } from 'styled-components'

const Show = keyframes`
   from {
      opacity: 0;
      transform: scale(0);
   }
   to {
      opacity: 1;
      transform: scale(1);
   }
`

const ShowBig = keyframes`
   from {
      opacity: 0;
      transform: scale(0) rotate(90deg) translate(14.6%, 20.3%);
   }
   to {
      opacity: 1;
      transform: scale(1) rotate(90deg) translate(14.6%, 20.3%);
   }
`
const ShowSmall = keyframes`
   from {
      opacity: 0;
      transform: scale(0) rotate(90deg) translate(15%, 21.5%);
   }
   to {
      opacity: 1;
      transform: scale(1) rotate(90deg) translate(15%, 21.5%);
   }
`

const StyledWrapper = styled.section`
   position: relative;
   @media ${({ theme }) => theme.breakpoints.min.md} {
      width: 100%;
      height: 565px;
      overflow: hidden;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      padding: 0 20px;
   }
`

const ScrollWrapper = styled.div`
   @media ${({ theme }) => theme.breakpoints.min.md} {
      height: calc(100vw - 150px);
      width: 585px;
      overflow: auto;
      transform: rotate(-90deg) translate(20px, -585px);
      transform-origin: top right;
   }
`

const StyledRealizationTile = styled(RealizationTile)`
   @media ${({ theme }) => theme.breakpoints.max.md} {
      animation: ${Show} 0.5s ${({ areaIndex }) => 0.1 * areaIndex}s ease-out backwards;
   }

   @media ${({ theme }) => theme.breakpoints.min.md} {
      grid-area: ${({ areaIndex }) => `i${areaIndex}`};
      transform: rotate(90deg) translate(${({ isBig }) => (isBig ? '14.6%, 20.3%' : '15%, 21.5%')});
      transform-origin: center;
      width: ${({ isBig }) => (isBig ? '520px' : '250px')};
      height: ${({ isBig }) => (isBig ? '370px' : '175px')};
      ${({ isBig }) => css`
         animation: ${isBig ? ShowBig : ShowSmall} 0.5s ${({ areaIndex }) => 0.1 * areaIndex}s
            ease-out backwards;
      `}
   }
`

const StyledGrid = styled.div`
   display: grid;
   grid-gap: 20px;
   grid-auto-rows: 250px;

   @media ${({ theme }) => theme.breakpoints.min.md} {
      grid-template-areas: ${({ gridarea }) => gridarea};
      grid-template-columns: repeat(3, 175px);
      padding: 100px 0;
   }

   @media ${({ theme }) => `${theme.breakpoints.min.sm} and ${theme.breakpoints.max.md}`} {
      grid-template-columns: repeat(2, 1fr);
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      grid-template-columns: repeat(1, 1fr);
   }
`

const StyledArrowArea = styled.div`
   position: absolute;
   top: 0;
   right: 0;
   width: 100px;
   height: 568px;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   background-image: linear-gradient(270.72deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
   z-index: 3;
   opacity: 1;
   transition: opacity 0.2s ease-out;
   cursor: pointer;

   &.hide {
      opacity: 0;
      pointer-events: none;
   }

   @media ${({ theme }) => theme.breakpoints.max.md} {
      display: none;
   }
`

const StyledIcon = styled(SVG)`
   width: 32px;
   height: 32px;
   margin-right: 20px;
`

class RealizationsGridTemplate extends React.Component {
   constructor(props) {
      super(props)
      this.scrollbar = React.createRef()
   }

   state = {
      showArrow: true,
   }

   createGridArea() {
      const { data } = this.props
      const row1 = []
      const row2 = []
      const row3 = []

      data.map((item, i) => {
         const { highlight } = item
         const areaName = `i${i}`

         if (!highlight) {
            const upRowIndex = row1.indexOf('.')
            const downRowIndex = row3.indexOf('.')

            if (upRowIndex !== -1) {
               row1[upRowIndex] = areaName
            } else if (downRowIndex !== -1) {
               row3[downRowIndex] = areaName
            } else if (row1.length <= row3.length) {
               row1.push(areaName)
            } else if (row2.length === row3.length) {
               row2.push(areaName)
            } else {
               row3.push(areaName)
            }
         } else if (row1.length <= row2.length) {
            if (row1.length < row2.length) {
               row1.push('.')
            }
            row1.push(areaName, areaName)
            row2.push(areaName, areaName)
            const fillDownRow = row2.length - row3.length
            for (let j = 0; j < fillDownRow; j++) {
               row3.push('.')
            }
         } else {
            row1.push('.')
            row2.push(areaName, areaName)
            row3.push(areaName, areaName)
         }

         return true
      })

      const maxLength = Math.max(row1.length, row2.length, row3.length)
      let template = ``

      for (let i = 0; i < maxLength; i++) {
         template += `"${row3[i] ? row3[i] : '.'} ${row2[i] ? row2[i] : '.'} ${
            row1[i] ? row1[i] : '.'
         }" `
      }

      return template
   }

   handleScrollEvent(e) {
      const { showArrow } = this.state
      const { scrollTop } = e.target
      if (scrollTop === 0 && !showArrow) {
         this.setState({ showArrow: true })
      } else if (scrollTop !== 0 && showArrow) {
         this.setState({ showArrow: false })
      }
   }

   triggerScroll() {
      this.scrollbar.current.scrollTo({ top: 300, behavior: 'smooth' })
   }

   render() {
      const { data } = this.props
      const { showArrow } = this.state

      return (
         <StyledWrapper>
            <ScrollWrapper ref={this.scrollbar} onScrollCapture={(e) => this.handleScrollEvent(e)}>
               <StyledGrid gridarea={this.createGridArea()}>
                  {data.map(({ _id, color, thumbnail, name, link, gallery, highlight }, i) => (
                     <StyledRealizationTile
                        areaIndex={i}
                        key={_id}
                        isBig={highlight ? 'true' : null}
                        data={{ color, thumbnail, name, link, gallery }}
                     />
                  ))}
               </StyledGrid>
            </ScrollWrapper>
            <StyledArrowArea
               className={showArrow ? null : 'hide'}
               onClick={() => this.triggerScroll()}
            >
               <StyledIcon src={scrollbarArrowIcon} />
            </StyledArrowArea>
         </StyledWrapper>
      )
   }
}

RealizationsGridTemplate.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         _id: PropTypes.string,
         name: PropTypes.string,
         thumbnail: PropTypes.shape({
            path: PropTypes.string,
         }),
         link: PropTypes.string,
         gallery: PropTypes.arrayOf(
            PropTypes.shape({
               meta: PropTypes.shape({
                  title: PropTypes.string,
               }),
               path: PropTypes.string,
            }),
         ),
         color: PropTypes.string,
         highlight: PropTypes.bool,
      }),
   ).isRequired,
}

export default RealizationsGridTemplate
