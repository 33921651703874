export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX'
export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX'
export const LIGHTBOX_NEXT_IMAGE = 'LIGHTBOX_NEXT_IMAGE'
export const LIGHTBOX_PREV_IMAGE = 'LIGHTBOX_PREV_IMAGE'

export const openLightboxAction = (images, title) => (dispatch) => {
   dispatch({ type: OPEN_LIGHTBOX, payload: { images, title } })
}

export const closeLightboxAction = () => (dispatch) => {
   dispatch({ type: CLOSE_LIGHTBOX })
}

export const lightboxPrevImageAction = () => (dispatch) => {
   dispatch({ type: LIGHTBOX_PREV_IMAGE })
}

export const lightboxNextImageAction = () => (dispatch) => {
   dispatch({ type: LIGHTBOX_NEXT_IMAGE })
}
