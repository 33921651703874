import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
   *, *::before, *::after {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }

   html {
      font-size: 10px;
      font-family: 'Comfortaa', cursive;
   }

   body {
      font-size: 1.4rem;
      margin: 0;
      padding: 0;
      background-color: #3682fc;
   }

   input, button, textarea {
      font-family: 'Comfortaa', cursive !important;
   }

   .MuiCircularProgress-colorPrimary {
      color: white !important;
   }

   .MuiCircularProgress-colorSecondary {
      color: black !important;
   }
`

export default GlobalStyle
