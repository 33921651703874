import PropTypes from 'prop-types'
import React from 'react'
import SVG from 'react-inlinesvg'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`
   display: flex;
   justify-content: center;
   align-items: center;
   max-width: 100%;
   position: relative;
   color: ${({ background, theme }) => (background === 'white' ? theme.black : theme.white)};
   font-size: ${({ theme }) => theme.fontSize.m};
   border: none;
   overflow: hidden;
   cursor: pointer;
   text-decoration: none;
   text-align: center;
   padding: 0 20px;
   transition: opacity 0.2s linear;

   @media ${({ theme }) => theme.breakpoints.min.sm} {
      width: 375px;
   }

   @media ${({ theme }) => theme.breakpoints.max.sm} {
      width: calc(100% - 20px);
   }

   ${({ big }) =>
      big === 'true'
         ? css`
              height: 80px;
              border-radius: 40px;
           `
         : css`
              height: 60px;
              border-radius: 30px;
           `}

   ${({ background, theme }) => {
      switch (background) {
         case 'transparent':
            return css`
               background-color: ${theme.transparentWhite};
            `
         case 'white':
            return css`
               background-color: ${theme.white};
               svg * {
                  fill: ${theme.black};
               }
            `
         case 'gradient':
            return css`
               background-image: ${theme.gradient.horizontal};
            `
         default:
            return css`
               background-color: ${theme.white};
            `
      }
   }}

   ${({ background, theme }) =>
      background !== 'transparent' &&
      css`
         box-shadow: ${theme.shadow.standard};
      `}

   ::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
      transform: translateY(-50%) scale(0);
      transition: transform 0.3s ease-out;
      will-change: transform;
      z-index: 1;
      ${({ background, theme }) => {
         switch (background) {
            case 'transparent':
               return css`
                  background-color: ${theme.transparentWhite};
               `
            case 'white':
               return css`
                  background-image: ${theme.gradient.horizontal};
               `
            case 'gradient':
               return css`
                  background-color: ${theme.white};
               `
            default:
               return css`
                  background-color: ${theme.transparentWhite};
               `
         }
      }}
   }

   :hover::before {
      transform: translateY(-50%) scale(1);
   }

   :hover {
      color: ${({ background, theme }) => (background === 'gradient' ? theme.black : theme.white)};
      svg * {
         ${({ background, theme }) => {
            switch (background) {
               case 'white':
                  return css`
                     fill: ${theme.white};
                  `
               case 'gradient':
                  return css`
                     fill: ${theme.black};
                  `
               default:
                  return null
            }
         }}
      }
   }

   :disabled {
      pointer-events: none;
      opacity: 0.6;
   }
`

const StyledText = styled.span`
   position: relative;
   z-index: 2;
`

const StyledIcon = styled(SVG)`
   width: 32px;
   height: 32px;
   flex-shrink: 0;
   flex-grow: 0;
   flex-basis: 32px;
   margin-left: 10px;
   z-index: 2;
   background-size: contain;
`

const Button = ({ onClick, className, background, big, children, icon, to, type, disabled }) => (
   <StyledButton
      type={type}
      as={to ? Link : null}
      to={to}
      background={background}
      big={big ? 'true' : 'false'}
      className={className}
      onClick={() => onClick()}
      disabled={disabled}
   >
      <StyledText>{children}</StyledText>
      {icon && <StyledIcon src={icon} />}
   </StyledButton>
)

Button.propTypes = {
   children: PropTypes.string.isRequired,
   background: PropTypes.oneOf(['transparent', 'white', 'gradient']),
   big: PropTypes.bool,
   icon: PropTypes.string,
   to: PropTypes.string,
   type: PropTypes.string,
   className: PropTypes.string,
   onClick: PropTypes.func,
   disabled: PropTypes.bool,
}

Button.defaultProps = {
   background: 'transparent',
   big: false,
   icon: null,
   to: null,
   type: 'button',
   className: null,
   onClick: () => {},
   disabled: false,
}

export default Button
