import {
   CLOSE_LIGHTBOX,
   LIGHTBOX_NEXT_IMAGE,
   LIGHTBOX_PREV_IMAGE,
   OPEN_LIGHTBOX,
} from 'actions/lightbox'

const initialState = {
   isLightboxOpen: false,
   lightboxImages: [],
   lightboxImageIndex: 0,
   lightboxTitle: '',
}

const lightboxReducer = (state = initialState, action) => {
   switch (action.type) {
      case OPEN_LIGHTBOX: {
         return {
            ...state,
            lightboxImages: action.payload.images,
            isLightboxOpen: true,
            lightboxImageIndex: 0,
            lightboxTitle: action.payload.title,
         }
      }
      case CLOSE_LIGHTBOX: {
         return {
            ...state,
            isLightboxOpen: false,
         }
      }
      case LIGHTBOX_NEXT_IMAGE: {
         return {
            ...state,
            lightboxImageIndex:
               state.lightboxImageIndex < state.lightboxImages.length - 1
                  ? state.lightboxImageIndex + 1
                  : 0,
         }
      }
      case LIGHTBOX_PREV_IMAGE: {
         return {
            ...state,
            lightboxImageIndex:
               state.lightboxImageIndex > 0
                  ? state.lightboxImageIndex - 1
                  : state.lightboxImages.length - 1,
         }
      }
      default:
         return state
   }
}

export default lightboxReducer
